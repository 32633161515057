import { AxiosInstance } from 'axios'
import { useAuth } from './useAuth'

export function configureAuthorizationHeaderInterceptor(axiosInstance: AxiosInstance, prefix = 'Bearer') {
  axiosInstance.interceptors.request.use(async (config) => {
    const auth = useAuth()

    config.headers = config.headers ?? {}
    if (auth.isAuthenticated) {
      const token = await auth.getAccessToken()
      config.headers.Authorization = `${prefix} ${token}`

      if (
        config.url?.startsWith(
          import.meta.env.VITE_API_SAIFTY_QUERY_BUILDER_BASE_URL || window.ENV.API_QUERY_BUILDER_BASE_URL
        )
      ) {
        config.headers.token = token
        config.params = { ...config.params, tenant_id: auth.user?.id }
      }
    }
    return config
  })
}
