import { LogLevel } from '@azure/msal-browser'
import { AuthProvider, Auth0Provider, MSALProvider } from './auth/types'
import router from './router'

const auth0Provider: Auth0Provider = {
  name: 'auth0',
  config: {
    domain: import.meta.env.VITE_AUTH0_DOMAIN || window.ENV.AUTH0_DOMAIN,
    client_id: import.meta.env.VITE_AUTH0_CLIENT_ID || window.ENV.AUTH0_CLIENT_ID,
    audience: import.meta.env.VITE_AUTH0_AUDIENCE || window.ENV.AUTH0_AUDIENCE,
    dbName: import.meta.env.VITE_AUTH0_DB_NAME || window.ENV.AUTH0_DB_NAME,
    redirect_uri: window.location.origin + router.resolve({ name: 'dashboard' }).href,
    authorizeTimeoutInSeconds: 10,
  },
}

const msalProvider: MSALProvider = {
  name: 'msal',
  config: {
    auth: {
      clientId: import.meta.env.VITE_MSAL_CLIENT_ID || window.ENV.MSAL_CLIENT_ID,
      authority: import.meta.env.VITE_MSAL_AUTHORITY || window.ENV.MSAL_AUTHORITY, // See: https://learn.microsoft.com/en-us/azure/active-directory/develop/msal-client-application-configuration
      redirectUri: window.location.origin,
      postLogoutRedirectUri: window.location.origin,
      navigateToLoginRequestUrl: true, // This option automatically redirects to the origin URL after the user gets successfully authenticated, this is different than the redirectUri because it can be any protected page
    },
    system: {
      loggerOptions: {
        loggerCallback: (level: LogLevel, message: string, containsPii: boolean): void => {
          if (containsPii) {
            return
          }
          switch (level) {
            case LogLevel.Error:
              console.error(message)
              return
            case LogLevel.Info:
              console.info(message)
              return
            case LogLevel.Verbose:
              console.debug(message)
              return
            case LogLevel.Warning:
              console.warn(message)
              return
          }
        },
        piiLoggingEnabled: false,
        logLevel: LogLevel.Verbose,
      },
    },
  },
}

export const authProvider: AuthProvider =
  import.meta.env.VITE_AUTH_PROVIDER === 'msal' || window.ENV.AUTH_PROVIDER === 'msal' ? msalProvider : auth0Provider
